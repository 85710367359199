import React from "react";
import { createRoot, hydrateRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import "./translations/i18n";
import App from "./App";
import {
  Episode0,
  Episode1,
  Episode2,
  Episode3,
  Episode4,
  Episode5,
  Episode6,
  Episode7,
  Episode8,
  Episode9,
  Episode10,
  Episode11,
  Episode12,
  Episode13,
  Episode14,
  Episode15,
  Episode16,
} from "./Episodes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/episode0",
    element: <Episode0 />,
  },
  {
    path: "/episode1",
    element: <Episode1 />,
  },
  {
    path: "/episode2",
    element: <Episode2 />,
  },
  {
    path: "/episode3",
    element: <Episode3 />,
  },
  {
    path: "/episode4",
    element: <Episode4 />,
  },
  {
    path: "/episode5",
    element: <Episode5 />,
  },
  {
    path: "/episode6",
    element: <Episode6 />,
  },
  {
    path: "/episode7",
    element: <Episode7 />,
  },
  {
    path: "/episode8",
    element: <Episode8 />,
  },
  {
    path: "/episode9",
    element: <Episode9 />,
  },
  {
    path: "/episode10",
    element: <Episode10 />,
  },
  {
    path: "/episode11",
    element: <Episode11 />,
  },
  {
    path: "/episode12",
    element: <Episode12 />,
  },
  {
    path: "/episode13",
    element: <Episode13 />,
  },
  {
    path: "/episode14",
    element: <Episode14 />,
  },
  {
    path: "/episode15",
    element: <Episode15 />,
  },
  {
    path: "/episode16",
    element: <Episode16 />,
  },
  {
    path: "*",
    element: <App />,
  },
]);

const container = document.getElementById("root");

if (container && container.hasChildNodes()) {
  hydrateRoot(container,
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
} else if (container) {
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
